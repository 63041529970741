(function($) {
  $('.is-scrolled a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });	
	$(document).ready(function(){
    $('a[href="#top"]').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 'slow');
        return false;
    });
    $(".slides").responsiveSlides({
        timeout: 4000,
        speed: 1800,
        pager: true,
        nav: true,
        prevText: "«",   // String: Text for the "previous" button
        nextText: "»",       // String: Text for the "next" button
        namespace: "slides"
    });
	});
})(jQuery);